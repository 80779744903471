import cNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const Str = {
  firstToUpperCase(str?: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  addCpfCnpjMask(str?: string) {
    if (!str) return '';
    str = Str.removeNonNumbers(str);
    if (str.length === 11) {
      // CPF
      return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (str.length === 14) {
      // CNPJ
      return str.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    }
    return '';
  },

  addDateMask(str?: string) {
    if (!str) return '';
    str = Str.removeNonNumbers(str);
    if (str.length === 11) {
      // CPF
      return str.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    } /* else if (str.length === 14) {
      // CNPJ
      return str.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    } */
    return '';
  },

  validDate(date: string) {
    const regDate = /(\d{2})\/(\d{2})\/(\d{4})/;
    if (!regDate.test(date)) {
      return false;
    }

    // TODO: verificar se é um período de data válido aqui.

    return true;
  },

  maskDate(date: string, isBar: boolean = false) {
    console.log('AQUIIIIIIII no maskDate');

    // Verifica se o usuário está digitando a tecla / após o MÊS para evitar conflitos.
    if (date.charAt(5) === '/' && isBar) {
      if (date.length === 7) {
        return date.substring(0, 6);
      } else if (date.length === 6) {
        return date;
      }
    }

    // Verifica se o usuário está digitando a tecla / após o DIA para evitar conflitos.
    if (date.charAt(2) === '/' && isBar) {
      if (date.length === 4) {
        return date.substring(0, 3);
      } else if (date.length === 3) {
        return date;
      }
    }

    let formattedDate = date.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    formattedDate = formattedDate.substr(0, 8); // Limita o comprimento máximo para 8 caracteres (ddmmyyyy)

    if (formattedDate.length > 4) {
      formattedDate =
        formattedDate.substr(0, 2) +
        '/' +
        formattedDate.substr(2, 2) +
        '/' +
        formattedDate.substr(4);
    } else if (formattedDate.length > 2) {
      formattedDate =
        formattedDate.substr(0, 2) + '/' + formattedDate.substr(2);
    }

    return formattedDate;
  },

  addPhoneMask(str?: string, ddd?: string) {
    if (!str) return;
    str = Str.removeNonNumbers(str);
    ddd = ddd || '62';
    ddd = Str.removeNonNumbers(ddd);

    if (str.length === 8) {
      return str.replace(/(\d{4})(\d{4})/, `(${ddd}) 9 $1-$2`);
    }

    if (str.length === 9) {
      return str.replace(/(\d{1})(\d{4})(\d{4})/, `(${ddd}) $1 $2-$3`);
    }

    if (str.length === 10) {
      return str.replace(/(\d{2})(\d{4})(\d{4})/, '($1) 9 $2-$3');
    }

    if (str.length === 11) {
      return str.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }

    return '';
  },

  removeNonNumbers(str?: string) {
    if (!str) return '';
    return str.replace(/\D/g, '');
  },

  convertDate(date: any) {
    if (date) {
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }
  },

  convertDateCreatedAt(date: any) {
    if (date) {
      date = date.split('T')[0];
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }
  },

  countDate(date1: any, date2: any) {
    // @ts-ignore
    const diffInMs = new Date(date2) - new Date(date1);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays;
  },

  getDatesBetween(startDate: any, endDate: any) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      // @ts-ignore
      dates.push(new Date(currentDate).toLocaleDateString('pt-br'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  },
  arrayGroupBy(array: any, key: any) {
    return array.reduce(
      (acc: any, item: any) => ({
        ...acc,
        [item[key]]: [...(acc[item[key]] ?? []), item]
      }),
      {}
    );
  },

  /**
   * Concatena classes para o Tailwind
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tw: (className?: string, ...args: any) => twMerge(className, cNames(args))
};

export default Str;
