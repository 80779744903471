import {
  Navigate,
  Routes as ReactRouterDomRoutes,
  Route,
  RouteProps,
  redirect,
  useNavigate
} from 'react-router-dom';
import Storage from '../helpers/Storage';
import AppRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dashboard } from '../components/admin';
import { NotFoundPage } from '../components/pages/NotFoundPage';

// TODO: importar as novas rotas.
function authenticated() {
  const logged = !!Storage.getApiToken();
  const userData = !!Storage.getUserData();

  return logged && userData;
}

const NOT_FOUND_REDIRECT = authenticated() ? '/admin/passaros' : '/entrar';
function PrivateRoute({ children }: RouteProps) {
  // TODO: melhorar essa autenticação para não armazenar o token pra sempre.
  // TODO: armazenar um timestamp com a duração da sessão do back no storage e verifica-lo durante a sessão pode ser suficiente para desconectar o usuário em caso de sessão expirada.
  const logged = !!Storage.getApiToken();
  const userData = !!Storage.getUserData();

  return authenticated() ? <>{children}</> : <Navigate to="/entrar" />;
}

export function Routes() {
  const navigate = useNavigate();
  return (
    <ReactRouterDomRoutes>
      {AppRoutes.map(({ path, requiresAuth, element }) =>
        requiresAuth ? (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <ToastContainer autoClose={5000} theme="colored" icon={false} />
                {element}
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={
              <>
                <ToastContainer autoClose={5000} theme="colored" icon={false} />
                {element}
              </>
            }
          />
        )
      )}
      <Route
        key="/*"
        path="/*"
        element={<Navigate to={NOT_FOUND_REDIRECT} />}
      />
    </ReactRouterDomRoutes>
  );
}
