/* eslint-disable react-hooks/exhaustive-deps */
import { z } from 'zod';
import { useEffect, useMemo, useState } from 'react';
import * as ApiService from '../../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Str from '../../../helpers/Str';
import UserDTO from '../../../dto/User/UserDTO';

const formSchema = z.object({
  name: z.string().min(1, 'O campo "nome" é obrigatório.'),
  password: z.string().min(1, 'O campo "senha" é obrigatório.'),
  email: z
    .string()
    .min(1, 'O campo "email" é obrigatório.')
    .email('Email inválido')
});

interface FormProps {
  formName: string;
  dto?: UserDTO;
}

const UserForm = ({ formName, dto }: FormProps) => {
  const IS_EDIT = !!dto;
  const REDIRECT_PATH = '/admin/usuarios';
  const API_ENDPOINT = '/user';
  const navigate = useNavigate();

  console.log(dto);

  const [userId, setUserId] = useState(dto?.id || '');
  const [userName, setUserName] = useState(dto?.name || '');
  const [userEmail, setUserEmail] = useState(dto?.email || '');
  const [userStatus, setUserStatus] = useState(
    dto?.status == 1 ||
      dto?.status == 0 ||
      dto?.status === true ||
      dto?.status === false
      ? dto?.status
      : true
  );
  const [userIsAdmin, setUserIsAdmin] = useState(dto?.is_admin || false);
  const [userPassword, setUserPassword] = useState(dto?.password || '');

  const [fieldErrors, setFieldErrors] = useState<{
    name?: string;
    email?: string;
    password?: string;
  }>({});
  const [hasError, setHasError] = useState(true);

  const sendData = async () => {
    const formData = getFormData();

    try {
      const result = IS_EDIT
        ? await ApiService.update({
            endpoint: API_ENDPOINT,
            formData,
            id: userId
          })
        : await ApiService.create({
            endpoint: API_ENDPOINT,
            formData
          });

      console.log('result: ', result);

      if (result?.data?.success && result?.data?.message?.length) {
        toast.success(result.data.message || 'Dados enviados com sucesso.');
        navigate(REDIRECT_PATH);
        return;
      }

      toast.error(
        result?.data?.error ||
          'Ocorreu um erro ao tentar cadastrar o usuário. Tente novamente em alguns instantes.'
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao tentar cadastrar o usuário. Tente novamente em alguns instantes.'
      );
      console.log(error);
    }
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!handleErrors()) {
      sendData();
    }
  };

  const getFormData = () => {
    const payload: Omit<UserDTO, 'id'> = {
      name: userName,
      email: userEmail,
      status: userStatus,
      is_admin: userIsAdmin,
      password: userPassword
    };

    return payload;
  };

  const handleErrors = () => {
    const validationResult = formSchema.safeParse(getFormData());

    // @ts-ignore
    const errors = validationResult.error?.flatten()?.fieldErrors || {};

    setFieldErrors((prevState) => ({
      ...prevState,
      name: errors?.['name']?.[0] || '',
      email: errors?.['email']?.[0] || '',
      password: errors?.['password']?.[0] || ''
    }));

    // Validando a condicional de erros.
    /* setHasError(
      !!(
        fieldErrors.name?.length ||
        fieldErrors.email?.length ||
        fieldErrors.password?.length
      )
    ); */

    return errors?.name?.[0] || errors?.email?.[0] || errors?.password?.[0]
      ? true
      : false;
  };

  useEffect(() => {
    /* setHasError(
      !!(
        fieldErrors.name?.length ||
        fieldErrors.email?.length ||
        fieldErrors.password?.length
      )
    ); */
  }, [fieldErrors]);

  return (
    <>
      <div className="border-b border-gray-900/10 pb-12">
        {/* TODO: mudar estes textos quando estiver editando um usuário */}
        <div className="flex justify-between">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {IS_EDIT
                ? 'Edição de informações do usuário'
                : 'Cadastro de novo usuário.'}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Preencha os campos de acordo com as informações do usuário.
            </p>
          </div>
        </div>

        <form
          name={formName}
          className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
          onSubmit={handleSubmitForm}
        >
          <div className="sm:col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nome
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                value={userName}
                // required={true}
                autoComplete="given-name"
                className={Str.tw(
                  fieldErrors?.name ? 'ring-red-600' : 'ring-gray-300',
                  'required:placeholder block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                )}
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
              />
              {fieldErrors?.name && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.name}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                value={userEmail}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setUserEmail(event.target.value);
                }}
              />
              {fieldErrors?.email && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.email}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Senha
            </label>
            <div className="mt-2">
              <input
                type="password"
                name="password"
                id="password"
                value={userPassword}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setUserPassword(event.target.value);
                }}
              />
              {fieldErrors?.password && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.password}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="user-dad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Status
            </label>
            <div className="mt-2">
              <select
                id="user-dad"
                name="user-dad"
                value={userStatus ? 1 : 0}
                autoComplete="user-dad-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-full sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setUserStatus(!!event.target.value ? 1 : 0);
                }}
              >
                <option value={1} key={1}>
                  Ativo
                </option>
                <option value={0} key={0}>
                  Inativo
                </option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="user-dad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Usuário administrador
            </label>
            <div className="mt-2">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={!!userIsAdmin}
                className="h-4 w-4 rounded border-gray-500 text-indigo-600 focus:ring-indigo-600"
                onChange={(event) => {
                  setUserIsAdmin(event.target.checked ? 1 : 0);
                }}
              ></input>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => navigate('/admin/usuarios')}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserForm;
