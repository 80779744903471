/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import BirdForm from './BirdForm';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as ApiService from '../../../services/api';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import Loading from '../../common/Loading';

export function EditBirdForm() {
  const { id } = useParams();
  const [dto, setDto] = useState<BirdDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDTO = async () => {
    try {
      if (!id) {
        toast.error('Ocorreu um erro ao tentar buscar o pássaro.');
        console.log('O id é obrigatório para buscar o pássaro para edita-lo.');

        return;
      }
      const result = await ApiService.getOne<BirdDTO>({
        endpoint: '/bird',
        id
      }); 

      if (result.isError === false && result?.data) {
        setDto(result.data);
        setIsLoading(false);
        return;
      }

      toast.error(
        result?.data?.error ||
          'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    }
  };

  useEffect(() => {
    getDTO();
  }, []);
  
  return (
    <>
      {isLoading ? (
        <>
          <span className="flex items-center justify-center h-64">
            <Loading fill="fill-gray-900" />
          </span>
        </>
      ) : (
        <>
          <BirdForm formName="bird" dto={dto} />
        </>
      )}
    </>
  );
}
