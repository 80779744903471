import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses
} from 'react-pro-sidebar';
import menus, { MenuInterface } from '../../../router/menus';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createElement } from 'react';
import logo from '../../../assets/img/logo.png';
import { IoIosLogOut } from 'react-icons/io';
import authApi from '../../../services/authApi';
import Storage from '../../../helpers/Storage';
import HomeSvg from 'assets/icone/home.svg';

import { logoutUser } from '../../../utils/functions';

const corDestaque = '#efd717';
export default function SideBar() {
  const navigate = useNavigate();
  //https://azouaoui-med.github.io/react-pro-sidebar/?path=/docs/submenu--root-styles
  //https://www.npmjs.com/package/react-pro-sidebar
  const { pathname: currentPath } = useLocation();
  const validatePage = currentPath.split('/')[1];

  const userData = Storage.getUserData() ?? undefined;

  /* async function logoutUser() {
    await authApi.logout();
    Storage.removeApiToken();
    Storage.removeUserData();
    // window.location.href = '/';
    navigate('/entrar');
  } */

  if (!userData?.usuario?.is_admin) {
    menus.forEach((menu, key) => {
      if (menu.onlyAdmin) {
        menus.splice(key, 1);
      }
    });
  }

  /* if (!userData?.usuario?.is_admin) {
    menus.push(...menus.filter((menu) => !menu.onlyAdmin));
  } */

  return (
    <div className={'flex h-screen bg-bl max-[800px]:hidden'}>
      <Sidebar
        backgroundColor="#061F46"
        rootStyles={{
          borderColor: '#092a5e'
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (active) {
                return {
                  color: corDestaque,
                  '&:hover': {
                    backgroundColor: '#092a5e',
                    color: corDestaque
                  }
                };
              }
              return {
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#092a5e',
                  color: '#fff'
                }
              };
            }
          }}
          //cor do icone
          rootStyles={{
            [`.${menuClasses.icon}`]: {
              color: corDestaque,
              fontSize: '25px'
            }
          }}
        >
          <aside className="w-auto flex justify-center mt-5">
            <img src={logo} className="" alt="logo" />
          </aside>
          <div className={'mt-5'}>
            {menus.map((menu: any, index: number) => {
              if (menu.subMenus) {
                return (
                  <SubMenu
                    key={menu.title + index}
                    icon={createElement(menu.icon)}
                    label={menu.title}
                    rootStyles={{
                      ['& > .' + menuClasses.button]: {
                        backgroundColor: '#061F46',
                        color: '',
                        '&:hover': {
                          backgroundColor: '#092a5e'
                        },
                        fontSize: '16px'
                      },
                      ['.' + menuClasses.subMenuContent]: {
                        backgroundColor: '#0b3675'
                      }
                    }}
                  >
                    {menu.subMenus.map((subMenu: any, indexSub: number) => {
                      return (
                        <MenuItem
                          key={subMenu.title + indexSub}
                          component={<Link to={subMenu.path} />}
                          active={currentPath === subMenu.path}
                        >
                          {subMenu.title}
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <MenuItem
                    icon={createElement(menu.icon)}
                    component={<Link to={menu.path} />}
                    active={'/' + validatePage === menu.path}
                  >
                    {menu.title}
                  </MenuItem>
                );
              }
            })}
          </div>
        </Menu>
        <div
          className={'flex py-5 justify-center  bottom-0 w-[33vh]  absolute'}
        >
          <button
            onClick={logoutUser}
            className="bg-[#E4EDFC33] hover:bg-[#c9d3e533] text-white font-bold py-5 px-20 rounded"
          >
            <div className={'flex'}>
              <IoIosLogOut color={corDestaque} size={25} className={'mr-2'} />{' '}
              Sair
            </div>
          </button>
        </div>
      </Sidebar>
    </div>
  );
}
