import React, { useState } from 'react';
import useForm from "../../hooks/useForm";
import { LoginData } from "../../types";
import authApi from "../../services/authApi";
import Storage from '../../helpers/Storage';
import Input from "../../components/input/Input";
import Form from "../../components/form/Form";
export function SignUp() {
  // TODO: construir formulário de criar conta.
    // TODO: cadastrar este usuário como cliente.
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { form, isFormInvalid, handleChange } = useForm<LoginData>({
    validator: (data) => !data.password || !data.email
  });

  async function handleSubmit() {
    setErrorMessage('');
    setIsLoading(true);

    const { data, isError } = await authApi.login(form);
    if (isError) {
      setIsLoading(false);
      setErrorMessage(data.message);
      return;
    }

    console.log('loginData: ', data);


    Storage.setApiToken(data.data.token);
    Storage.setUserData({ usuario: data.data.usuario });
    window.location.href = '/processos';
  }


  return (
    <section className="h-screen">
      <div className="container h-full px-6 py-24">
        <div className="g-6 md:flex h-full w-full flex-wrap items-center justify-center">
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
            <h1 className="text-3xl font-semibold text-center text-purple-700 ">
              Login
            </h1>
            <Form className="mt-5 " onSubmit={handleSubmit}>
              <Input
                large
                hideLabel
                inputType="email"
                className="my-5"
                value={form?.email}
                setValue={(v) => handleChange(v, 'email')}
              />

              <Input
                large
                hideLabel
                inputType="password"
                value={form?.password}
                setValue={(v) => handleChange(v, 'password')}
              />
              <div className="mt-6">
                <button
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                  Login
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}