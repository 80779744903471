import SideBar from "../SideBar";
import Navbar from "../NavBar";
import './../../../style/admin.css';

export interface IPageContainer {
    children: React.ReactNode;
    pageTitle?: string;
    pageTitleSecond?: string;
}

export default function PageContainer({children, pageTitle, pageTitleSecond}: IPageContainer) {
    return(
        <div className={'flex'}>
            <SideBar/>
            <div className={'flex flex-col w-full mb-5'}>
                <Navbar admin={true} pageTitle={pageTitle} pageTitleSecond={pageTitleSecond}/>
                <div className={'px-5 pt-5 h-[calc(100vh_-_95px)] overflow-auto'}>
                    {children}
                </div>

            </div>

        </div>
    )
}