import { RouteInterface } from './RouteInterface';
import { Home, SignUp, MyAccount } from '../components/pages';
import Login from '../pages/Auth/Login';
import { Details, Document, FamilyTree, List } from '../components/pages/birds';
import { PrintDocument } from '../components/pages/birds/PrintDocument';

const BIRD_PATH = '/passaros';

const BirdRoutes: RouteInterface[] = [
  {
    path: `${BIRD_PATH}`,
    element: <List />
  },
  {
    path: `${BIRD_PATH}/:id`,
    element: <Details />
  },
  {
    path: `${BIRD_PATH}/:id/carteirinha`,
    element: <Document />
  },
  {
    path: `${BIRD_PATH}/:id/carteirinha-impressa`,
    element: <PrintDocument />
  },
].map((route): RouteInterface => {
  return {
    ...route,
    requiresAuth: true
  };
});

const WebRoutes: RouteInterface[] = [
  ...BirdRoutes,
  {
    path: '/entrar',
    element: <Login />
  },
  {
    path: '/criar-conta',
    element: <SignUp />
  },
  {
    path: '/minha-conta',
    element: <MyAccount />
  }
];

export default WebRoutes;
