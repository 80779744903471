import Storage from '../helpers/Storage';
import authApi from '../services/authApi';

export async function logoutUser() {
  const user = Storage.getUserData();
  if (user?.usuario?.id) {
    await authApi.logout(user.usuario.id);
    Storage.removeApiToken();
    Storage.removeUserData();
    window.location.href = '/entrar';
  }
}

/**
 * Função para ignorar acentuação na pesquisa.
 * @param keyword 
 * @returns string
 */
export function replaceSpecialChars(keyword: string): string {
  keyword = keyword.replace(/[ÀÁÂÃÄÅ]/, 'A');
  keyword = keyword.replace(/[àáâãäå]/, 'a');
  keyword = keyword.replace(/[ÈÉÊË]/, 'E');
  keyword = keyword.replace(/[èéêë]/g, 'e');
  keyword = keyword.replace(/[Ç]/, 'C');
  keyword = keyword.replace(/[ç]/, 'c');
  keyword = keyword.replace(/[ÌÍÎÏ]/g, 'I');
  keyword = keyword.replace(/[ìíîï]/g, 'i');
  keyword = keyword.replace(/[ÒÓÔÕÖ]/g, 'O');
  keyword = keyword.replace(/[òóôõö]/g, 'o');
  keyword = keyword.replace(/[ÙÚÛÜ]/g, 'U');
  keyword = keyword.replace(/[ùúûü]/g, 'u');
  keyword = keyword.replace(/[Ñ]/g, 'N');
  keyword = keyword.replace(/[ñ]/g, 'n');

  // o resto

  return keyword.replace(/[^a-z0-9]/gi, '');
}
