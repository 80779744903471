import { useNavigate, useParams } from 'react-router-dom';
import { FamilyTree } from './FamilyTree';
import './../../../style/page.css';
import './../../../style/genealogy.css';
import logo from '../../../assets/img/lagrimas-do-sol-logo-impressao.png';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import { toast } from 'react-toastify';
import * as ApiService from '../../../services/api';
import { getFamilyTree } from '../../../services/birdService';
import { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Genealogy, TreeBird } from './Genealogy';
import Str from '../../../helpers/Str';
import { GenderColors } from '../../../enums/GenderColors';
import Loading from '../../common/Loading';

export function PrintDocument() {
  // TODO: chamar o método de imprimir aqui no documento, adicionando a classe flex na div para ela manter os itens horizontais.
  // TODO: limitar as camadas da árvore quando for imprimir para não ficar muito grande. (usabilidade)
  const navigate = useNavigate();
  const { id } = useParams();
  const [dto, setDto] = useState<BirdDTO>();

  // TODO: usar esse state para mudar a view na hora da impressão.
  const [isPrint, setIsPrint] = useState<boolean>(true);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const [treeReady, setTreeReady] = useState<boolean>(false);
  const [canPrint, setCanPrint] = useState<boolean>(false);

  const [treeData, setTreeData] = useState<TreeBird | undefined>();

  useEffect(() => {
    setTimeout(() => {
      if (treeData && dto) {
        setCanPrint(true);
        // handlePrint();
      }
    }, 100);
  }, [treeData, dto]);

  useEffect(() => {
    if (treeData && dto && canPrint) {
      handlePrint();
    }
  }, [treeData, dto, canPrint]);

  const contentDocument = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    /* onBeforeGetContent: () => {
      setIsPrint(true);
    }, */
    // TODO: passar a classe da impressão corretamente.
    onBeforePrint: () => {
      // setIsPrinting(true);
    },
    content: () => {
      // setIsPrint(true);
      return contentDocument.current;
    },
    onAfterPrint: () => {
      // setIsPrinting(false);
      navigate(`/passaros/${id}/carteirinha`);
    }
  });

  const loadTree = async function () {
    if (id) {
      const tree = await getFamilyTree(id);
      if (tree) {
        setTreeData(tree);
      }
    }
  };

  // Busca dos dados da árvore separado do componente aqui.
  useEffect(() => {
    loadTree();
  }, []);

  const getDTO = async () => {
    try {
      if (!id) {
        toast.error('Ocorreu um erro ao tentar buscar o pássaro.');
        console.log('O id é obrigatório para buscar o pássaro para edita-lo.');

        return;
      }

      if (!dto) {
        const result = await ApiService.getOne<BirdDTO>({
          endpoint: '/bird',
          id
        });

        if (result.isError === false && result?.data) {
          setDto(result.data);
          return;
        }

        toast.error(
          result?.data?.error ||
            'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
        );
      }
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    }
  };

  useEffect(() => {
    getDTO();
  }, []);

  /* useEffect(() => {
    setTimeout(() => {
      // setIsPrint(false);
    }, 50);
  }, []); */

  return (
    <div id="print-main" ref={contentDocument}>
      {!canPrint ? (
        <span className="flex items-center justify-center mt-64">
          <Loading fill="fill-gray-900" width="w-12" height="h-12" />
        </span>
      ) : (
        <div
          /* id='print-main' */ style={{
            marginLeft: '3mm',
            marginTop: '3mm'
          }}
        >
          <div
            className=""
            id="card-print"
            style={{
              minWidth: '20cm',
              width: '20cm',
              maxWidth: '20cm',
              minHeight: '7cm',
              height: '7cm',
              maxHeight: '7cm',
              display: 'flex'
            }}
          >
            {/* Dados da carteirinha. */}

            <div
              className="
          
                border
                border-black
                
                
                box-border 
                p-1
                print-card
                "
              style={{ position: 'relative', width: '50%' }}
            >
              {/* Nome, logo e gênero */}
              <div className="grid grid-cols-6">
                <div className="col-span-3 flex justify-start items-start">
                  <div>
                    {/* <span
                className="text-5xl font-bold text-left"
                style={{
                  color:
                    (dto?.gender === 'F' && GenderColors['female']) ||
                    (dto?.gender === 'M' && GenderColors['male']) ||
                    GenderColors['none']
                }}
              >
                {dto?.name}
              </span> */}
                    {/* <br /> */}
                    <span
                      className="text-3xl font-bold"
                      style={{
                        color:
                          (dto?.gender === 'F' && GenderColors['female']) ||
                          (dto?.gender === 'M' && GenderColors['male']) ||
                          GenderColors['none']
                      }}
                    >
                      {(dto?.gender === 'F' && 'FÊMEA') ||
                        (dto?.gender === 'M' && 'MACHO') ||
                        ''}
                    </span>
                  </div>
                </div>
                <div className="col-span-3 flex justify-end align-top">
                  <img src={logo} className="h-20" alt="logo" height={'1px'} />
                </div>
              </div>

              {/* Nome grande */}
              <div
                className="flex justify-center items-center rounded-full print-doc-items-gap"
                style={{
                  backgroundColor:
                    (dto?.gender === 'F' && GenderColors['female']) ||
                    (dto?.gender === 'M' && GenderColors['male']) ||
                    GenderColors['none']
                }}
              >
                <span className="text-white text-xl font-bold text-center print-doc-name">
                  {/* {dto?.ringNumber} */}
                  {dto?.name}
                </span>
              </div>

              <div className="">
                {/* Anilha e nascimento */}
                {/* <div className="grid grid-cols-6">
                  <div className="col-span-3 flex justify-center items-center">
                    <span className="font-bold">Anilha:&nbsp;</span>{' '}
                    {dto?.ringNumber ?? ''}
                    <p className=''>
                    </p>
                  </div>
                  <div className="col-span-3 flex justify-center items-center">
                    <span className="font-bold">Nascimento:&nbsp;</span>
                    {dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''}
                  </div>
                </div> */}

                {/* Anilha e nascimento FLEX */}
                <div className="flex justify-around print-doc-items-gap">
                  <div className="">
                    <span className="font-bold text-center print-doc-text-normal">
                      Anilha:&nbsp;
                    </span>
                    <span className="font-normal text-center print-doc-text-normal">
                      {dto?.ringNumber ?? ''}
                    </span>
                  </div>
                  <div className="">
                    <span className="font-bold text-center print-doc-text-normal">
                      Nascimento:&nbsp;
                    </span>
                    <span className="font-normal text-center print-doc-text-normal">
                      {dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''}
                    </span>
                  </div>
                </div>

                {/* Tipo (Bicudo (Sporophilia maximiliani)) */}
                <div className="flex justify-center items-center print-doc-items-gap">
                  <p className="print-doc-text-normal">
                    Bicudo (Sporophilia maximiliani)
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-6 print-doc-items-gap">
                {/* Anilha grande */}
                <div
                  className="col-span-3 bg-slate-700 flex justify-center items-center rounded-full mr-2"
                  style={{ backgroundColor: GenderColors['male'] }}
                >
                  <span className="text-white font-bold text-center print-doc-parent">
                    {dto?.dad?.name}
                  </span>
                </div>

                <div
                  className="col-span-3 bg-slate-700 flex justify-center items-center rounded-full ml-2"
                  style={{ backgroundColor: GenderColors['female'] }}
                >
                  <span className="text-white font-bold text-center print-doc-parent">
                    {dto?.mom?.name}
                  </span>
                </div>
              </div>

              {/* Dados do criadouro */}
              <div className="flex justify-between print-doc-items-gap">
                <div className="text-center">
                  <p className="font-bold text-center print-doc-text-normal">
                    Criadouro
                  </p>
                  <p className="text-center print-doc-text-normal">
                    {dto?.breedPlace?.name ?? ''}
                  </p>
                </div>
                <div className="">
                  <p className="font-bold text-center print-doc-text-normal">
                    Responsável
                  </p>
                  <p className="text-center print-doc-text-normal">
                    {dto?.breedPlace?.responsible ?? ''}
                  </p>
                </div>
                <div className="">
                  <p className="font-bold text-center print-doc-text-normal">
                    CTF{' '}
                  </p>
                  <p className="text-center print-doc-text-normal">
                    {dto?.breedPlace?.ctf ?? ''}
                  </p>
                </div>
              </div>

              {/* <div className="grid grid-cols-12">
                <div className="col-span-5 text-center">
                  <p className="font-bold text-center">Criadouro:</p>
                  <p className="text-center">{dto?.breedPlace?.name ?? ''}</p>
                </div>
                <div className="col-span-5">
                  <p className="font-bold text-center">Responsável:</p>
                  <p className="text-center">{dto?.breedPlace?.responsible ?? ''}</p>
                </div>
                <div className="col-span-2">
                  <p className="font-bold text-center">CTF: </p>
                  <p className="text-center">{dto?.breedPlace?.ctf ?? ''}</p>
                </div>
              </div> */}
            </div>

            <div
              className="col-span-3 border border-black box-border print-card"
              style={{ position: 'relative', width: '50%' }}
            >
              {id && treeData && (
                <div id="print-doc-tree-body">
                  <div id="body-arvore">
                    <div>
                      {/* Pássaro principal. */}
                      <div id="wrapper">
                        <div className="branch">
                          {/* Pássaro PAI */}
                          <div className="entry">
                            <span className="label masc">
                              {treeData?.dad?.name?.toUpperCase() ?? ''}
                            </span>
                            {/* Avô Paterno */}
                            <div className="branch">
                              <div className="entry">
                                <span className="label masc">
                                  {treeData?.dad?.dad?.name?.toUpperCase() ??
                                    ''}
                                </span>
                                <div className="branch">
                                  <div className="entry">
                                    <span className="label masc">
                                      {treeData?.dad?.dad?.dad?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.dad?.dad?.dad?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.dad?.dad?.dad?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="entry">
                                    <span className="label fem">
                                      {treeData?.dad?.dad?.mom?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.dad?.dad?.mom?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.dad?.dad?.mom?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="entry">
                                <span className="label fem">
                                  {treeData?.dad?.mom?.name?.toUpperCase() ??
                                    ''}
                                </span>
                                <div className="branch">
                                  <div className="entry">
                                    <span className="label masc">
                                      {treeData?.dad?.mom?.dad?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.dad?.mom?.dad?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.dad?.mom?.dad?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="entry">
                                    <span className="label fem">
                                      {treeData?.dad?.mom?.mom?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.dad?.mom?.mom?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.dad?.mom?.mom?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Pássaro MÃE */}
                          <div className="entry">
                            <span className="label fem">
                              {treeData?.mom?.name?.toUpperCase() ?? ''}
                            </span>
                            <div className="branch">
                              <div className="entry">
                                <span className="label masc">
                                  {treeData?.mom?.dad?.name?.toUpperCase() ??
                                    ''}
                                </span>
                                <div className="branch">
                                  <div className="entry">
                                    <span className="label masc">
                                      {treeData?.mom?.dad?.dad?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.mom?.dad?.dad?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.mom?.dad?.dad?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="entry">
                                    <span className="label fem">
                                      {treeData?.mom?.dad?.mom?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.mom?.dad?.mom?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.mom?.dad?.mom?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="entry">
                                <span className="label fem">
                                  {treeData?.mom?.mom?.name?.toUpperCase() ??
                                    ''}
                                </span>
                                <div className="branch">
                                  <div className="entry">
                                    <span className="label masc">
                                      {treeData?.mom?.mom?.dad?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.mom?.mom?.dad?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.mom?.mom?.dad?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="entry">
                                    <span className="label fem">
                                      {treeData?.mom?.mom?.mom?.name?.toUpperCase() ??
                                        ''}
                                    </span>
                                    <div className="branch">
                                      <div className="entry">
                                        <span className="label masc">
                                          {treeData?.mom?.mom?.mom?.dad?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                      <div className="entry">
                                        <span className="label fem">
                                          {treeData?.mom?.mom?.mom?.mom?.name?.toUpperCase() ??
                                            ''}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* {!(treeReady && dto) ? 'Carregando...' : ''} */}
          </div>
        </div>
      )}
    </div>
  );
}
