import AdminRoutes from './AdminRoutes';
import { RouteInterface } from './RouteInterface';
import WebRoutes from './WebRoutes';

const Routes: RouteInterface[] = [
    ...AdminRoutes,
    ...WebRoutes,
    /* {
        // TODO: criar componente NotFound.tsx que redirecione o usuário para o admin se autenticado, e para o login se não autenticado.
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    } */
];

export default Routes;