import { useNavigate } from 'react-router-dom';

import * as ApiService from '../../../services/api';
import UserDTO from '../../../dto/User/UserDTO';
import { useEffect, useState } from 'react';
import Loading from '../../common/Loading';

export function ListUsers() {
  const navigate = useNavigate();

  const [dtos, setDtos] = useState<UserDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUsers = async () => {
    if (!dtos?.length) {
      try {
        const timeOutLoading = setTimeout(() => {
          setIsLoading(true);
        }, 1000);

        const result = await ApiService.listAll<UserDTO>({
          endpoint: '/user'
        });

        console.log(result);
        
        if (result?.isError === false && result?.data) {
          setDtos(result?.data);
        }
        clearTimeout(timeOutLoading);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-0">
      <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-8 bg-white">
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => navigate('/admin/usuarios/cadastro')}
        >
          Cadastrar novo
        </button>
        <div className="relative" style={{ display: 'none' }}>
          <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search-users"
            className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500     :ring-blue-500 :border-blue-500"
            placeholder="Pesquisar"
          />
        </div>
      </div>
      {isLoading ? (
        <span className="flex items-center justify-center h-64">
          <Loading fill="fill-gray-900" />
        </span>
      ) : (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nome
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {dtos?.map((user, index) => {
              return (
                <tr
                  className="bg-white border-b   hover:bg-gray-50 :bg-gray-600"
                  key={user?.id ?? index}
                >
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap "
                  >
                    <div className="ps-3">
                      <div className="text-base font-semibold">{user.name}</div>
                      <div className="font-normal text-gray-500">
                        {user.name}
                      </div>
                    </div>
                  </th>
                  <td className="px-6 py-4">
                  {user.email}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="h-2.5 w-2.5 me-2" /> {user.status ? 'Ativo' : 'Inativo'}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex justify-start gap-2">
                      <button
                        onClick={() =>
                          navigate(`/admin/usuarios/${user.id}/editar`)
                        }
                        className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
                      >
                        Editar
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}