import { Dashboard } from '../components/admin';
import {
  CreateBirdForm,
  EditBirdForm,
  ListBirds
} from '../components/admin/birds';
import { FamilyTree } from '../components/admin/birds/FamilyTree';
import {
  CreateBreedPlaceForm,
  EditBreedPlaceForm,
  ListBreedPlaces
} from '../components/admin/breed-places';
import {
  CreateUserForm,
  EditUserForm,
  ListUsers
} from '../components/admin/users';
import PageContainer from '../components/container/PageContainer';
import { RouteInterface } from './RouteInterface';

enum BasePaths {
  ADMIN_PATH = '/admin',
  BIRDS_PATH = '/passaros',
  USERS_PATH = '/usuarios',
  BREED_PLACES_PATH = '/criadouros'
}

const BirdRoutes: RouteInterface[] = [
  {
    path: `${BasePaths['BIRDS_PATH']}`,
    element: <ListBirds />
  },
  {
    path: `${BasePaths['BIRDS_PATH']}/cadastro`,
    element: <CreateBirdForm />
  },
  {
    path: `${BasePaths['BIRDS_PATH']}/:id/editar`,
    element: <EditBirdForm />
  },
  {
    path: `${BasePaths['BIRDS_PATH']}/:id/carteirinha`,
    element: <EditBirdForm />
  },
  {
    path: `${BasePaths['BIRDS_PATH']}/:id/arvore-genealogica`,
    element: <FamilyTree />
  }
];

const UserRoutes: RouteInterface[] = [
  {
    path: `${BasePaths['USERS_PATH']}`,
    element: <ListUsers />
  },
  {
    path: `${BasePaths['USERS_PATH']}/cadastro`,
    element: <CreateUserForm />
  },
  {
    path: `${BasePaths['USERS_PATH']}/:id/editar`,
    element: <EditUserForm />
  }
];

const BreedPlaceRoutes: RouteInterface[] = [
  {
    path: `${BasePaths['BREED_PLACES_PATH']}`,
    element: <ListBreedPlaces />
  },
  {
    path: `${BasePaths['BREED_PLACES_PATH']}/cadastro`,
    element: <CreateBreedPlaceForm />
  },
  {
    path: `${BasePaths['BREED_PLACES_PATH']}/:id/editar`,
    element: <EditBreedPlaceForm />
  }
];

const AdminRoutes: RouteInterface[] = [
  ...BirdRoutes,
  ...UserRoutes,
  ...BreedPlaceRoutes,
  {
    path: '/',
    element: <Dashboard />
  }
].map((route): RouteInterface => {
  /* route.requiresAuth = true
  route.path = BasePaths['ADMIN_PATH'] + route.path;
  route.element = <PageContainer>{route.element}</PageContainer>
  return route; */

  return {
    ...route,
    path: BasePaths['ADMIN_PATH'] + route.path,
    element: <PageContainer>{route.element}</PageContainer>,
    requiresAuth: true
  };
});

export default AdminRoutes;
