import React, { useState } from 'react';
import Tree from 'react-d3-tree';

enum COLORS {
  'm' = '#2F80ED',
  'f' = '#ED2F51',
  'current' = '#787878'
}

interface TreeNode {
  name: string;
  root?: boolean;
  gender?: 'm' | 'f';
  children?: TreeNode[];
  _collapsed?: boolean;
  attributes?: Record<string, any>;
}

// Componente para o estilo dos nós da árvore
const NodeStyle: React.FC<{ name?: string; gender?: 'm' | 'f' }> = ({
  name,
  gender
}) => {
  const bgColorByGender = !!gender ? COLORS[gender] : COLORS['current'];
  return (
    <div style={{ height: '40px', backgroundColor: bgColorByGender }}>
      <p style={{ color: '#ffffff', textAlign: 'center', lineHeight: '40px' }}>
        {name}
      </p>
    </div>
  );
};

export function FamilyTree() {
  const [treeData, setTreeData] = useState<TreeNode>({
    name: 'Alita SR',
    root: true,
    children: [
      {
        name: 'Pai de Alita',
        gender: 'm',
        children: [
          {
            name: 'Avô Paterno de Alita',
            gender: 'm',
            _collapsed: true,
            children: [
              {
                name: 'Bisavô Paterno de Alita',
                gender: 'm',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Paterno de Alita', gender: 'm' },
                  { name: 'Tataravó Paterna de Alita', gender: 'f' }
                ]
              },
              {
                name: 'Bisavó Paterna de Alita',
                gender: 'f',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Paterno de Alita', gender: 'm' },
                  { name: 'Tataravó Paterna de Alita', gender: 'f' }
                ]
              }
            ]
          },
          {
            name: 'Avó Paterna de Alita',
            gender: 'f',
            _collapsed: true,
            children: [
              {
                name: 'Bisavô Paterno de Alita',
                gender: 'm',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Paterno de Alita', gender: 'm' },
                  { name: 'Tataravó Paterna de Alita', gender: 'f' }
                ]
              },
              {
                name: 'Bisavó Paterna de Alita',
                gender: 'f',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Paterno de Alita', gender: 'm' },
                  { name: 'Tataravó Paterna de Alita', gender: 'f' }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Mãe de Alita',
        gender: 'f',
        _collapsed: true,
        children: [
          {
            name: 'Avô Materno de Alita',
            gender: 'm',
            _collapsed: true,
            children: [
              {
                name: 'Bisavô Materno de Alita',
                gender: 'm',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Materno de Alita', gender: 'm' },
                  { name: 'Tataravó Materna de Alita', gender: 'f' }
                ]
              },
              {
                name: 'Bisavó Materna de Alita',
                gender: 'f',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Materno de Alita', gender: 'm' },
                  { name: 'Tataravó Materna de Alita', gender: 'f' }
                ]
              }
            ]
          },
          {
            name: 'Avó Materna de Alita',
            gender: 'f',
            _collapsed: true,
            children: [
              {
                name: 'Bisavô Materno de Alita',
                gender: 'm',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Materno de Alita', gender: 'm' },
                  { name: 'Tataravó Materna de Alita', gender: 'f' }
                ]
              },
              {
                name: 'Bisavó Materna de Alita',
                gender: 'f',
                _collapsed: true,
                children: [
                  { name: 'Tataravô Materno de Alita', gender: 'm' },
                  { name: 'Tataravó Materna de Alita', gender: 'f' }
                ]
              }
            ]
          }
        ]
      }
    ]
  });

  const handleClick = (node: TreeNode) => {
    if (node.children) {
      // Inverte o estado do recolhimento ao clicar
      node._collapsed = !node._collapsed;
      setTreeData({ ...treeData });
    }
  };

  // Componente para o nó da árvore
  const CustomNode: React.FC<{ nodeData: TreeNode }> = ({ nodeData }) => (
    <foreignObject
      // width="100"
      // height="40"
      y={-20}
      x={nodeData.root ? -80 : -40}
      // onClick={() => handleClick(nodeData)}
      style={{
        width: `${nodeData.name.length * 0.8}%`,
        height: '100%',
        padding: '0 10px'
      }}
    >
      <NodeStyle name={nodeData.name} gender={nodeData.gender} />
    </foreignObject>
  );

  return (
    // <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
    // </div>
    <Tree
      data={treeData}
      // shouldCollapseNeighborNodes={true}
      zoom={0.5}
      nodeSize={{ x: 120, y: 120 }}
      translate={{ x: 300, y: 280 }}
      separation={{ siblings: 1, nonSiblings: 1 }}
      depthFactor={90}
      renderCustomNodeElement={(treeProps) => (
        <CustomNode nodeData={treeProps.nodeDatum} />
      )}
      // // TODO: resolver o problema do collapsible.
      // collapsible={true}
      pathFunc="diagonal"
    />
  );
}
