import React, { useState, useEffect } from 'react';
import useForm from '../../../hooks/useForm';
import { LoginData } from '../../../types';
import { useNavigate } from 'react-router-dom';
import authApi from '../../../services/authApi';
import Storage from '../../../helpers/Storage';
import Input from '../../../components/input/Input';
import Form from '../../../components/form/Form';
import Loading from '../../../components/common/Loading';
import logo from '../../../assets/img/lagrimas-do-sol-logo-impressao.png';
export default function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { form, isFormInvalid, handleChange } = useForm<LoginData>({
    validator: (data) => !data.password || !data.email
  });

  async function handleSubmit() {
    setErrorMessage('');
    setIsLoading(true);

    const { data, isError } = await authApi.login(form);
    if (isError) {
      setIsLoading(false);
      setErrorMessage(data?.data?.message ?? data?.message);
      return;
    }

    if (data?.data?.token && data?.data?.usuario) {
      Storage.setApiToken(data?.data?.token);
      Storage.setUserData({ usuario: data?.data?.usuario });
      setIsLoading(false);
      window.location.href = '/admin/passaros';
    } else {
      setIsLoading(false);
      setErrorMessage(
        data?.data?.message ??
          data?.message ??
          'Ocorreu um erro inesperado. Aguarde alguns instantes e tente novamente.'
      );
    }
  }

  return (
    <>
      <section className="h-screen">
        <div className="container h-full px-6">
          <div className="g-6 md:flex h-full w-full flex-wrap items-center justify-center">
            <div className="md:w-8/12 lg:ml-6 lg:w-5/12">
              <div className="flex justify-center py-0 my-10" style={{marginTop: "-10em"}}>
                <img src={logo} className="h-56" alt="logo" />
              </div>
              <h1 className="text-3xl font-semibold text-center text-blue-700 ">
                Login
              </h1>
              <Form className="mt-5 " onSubmit={handleSubmit}>
                <Input
                  large
                  hideLabel
                  inputType="email"
                  className="my-5"
                  value={form?.email}
                  setValue={(v) => handleChange(v, 'email')}
                />

                <Input
                  large
                  hideLabel
                  inputType="password"
                  value={form?.password}
                  setValue={(v) => handleChange(v, 'password')}
                />
                <div className="mt-6">
                  <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 justify-items-center">
                    {isLoading ? 'Entrando...' : 'Login'}
                  </button>
                </div>
                {errorMessage && (
                  <span className="text-red-600 text-sm">{errorMessage}</span>
                )}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
