import Str from '../../helpers/Str';

interface LoadingProps {
  fill?: string;
  width?: string;
  height?: string;
}

/**
 * Componente que retorna uma animação de carregamento.
 * @param {string} fill - Classe Tailwind para preenchimento do svg (fill-)
 * @param {string} width - Largura do SVG (w-).
 * @param {string} height - Altura do SVG (h-).
 */
export default function Loading({
  fill,
  width = '',
  height = ''
}: LoadingProps) {
  return (
    <svg
      className={Str.tw(
        'animate-spin -ml-1 mr-3 h-8 w-8',
        `${width} ${height}`
      )}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        className={Str.tw('opacity-75', fill)}
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
