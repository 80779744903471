import { useNavigate, useParams } from 'react-router-dom';
import { FamilyTree } from './FamilyTree';
import './../../../style/page.css';
import logo from '../../../assets/img/lagrimas-do-sol-logo-impressao.png';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import { toast } from 'react-toastify';
import * as ApiService from '../../../services/api';
import { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Genealogy } from './Genealogy';
import Str from '../../../helpers/Str';

/* interface DocumentProps {
  dto?: BirdDTO;
} */

export function Document() {
  // TODO: chamar o método de imprimir aqui no documento, adicionando a classe flex na div para ela manter os itens horizontais.
  // TODO: limitar as camadas da árvore quando for imprimir para não ficar muito grande. (usabilidade)
  const navigate = useNavigate();
  const { id } = useParams();
  const [dto, setDto] = useState<BirdDTO>();

  // TODO: usar esse state para mudar a view na hora da impressão.
  /* const [isPrint, setIsPrint] = useState<boolean>(true); */
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const contentDocument = useRef<HTMLDivElement>(null);

  const getDTO = async () => {
    try {
      if (!id) {
        toast.error('Ocorreu um erro ao tentar buscar o pássaro.');
        console.log('O id é obrigatório para buscar o pássaro para edita-lo.');

        return;
      }
      const result = await ApiService.getOne<BirdDTO>({
        endpoint: '/bird',
        id
      });

      if (result.isError === false && result?.data) {
        setDto(result.data);
        return;
      }

      toast.error(
        result?.data?.error ||
          'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    }
  };

  useEffect(() => {
    getDTO();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // setIsPrint(false);
    }, 50);
  }, []);

  return (
    <div className="px-9 pt-3" style={{ width: '100%', height: '100vh' }}>
      <div className="flex justify-between">
        <button
          onClick={() => {
            if (id) {
              return navigate(`/admin/passaros/${id}/editar`);
            } else {
              return navigate(-1);
            }
          }}
          className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
        >
          Voltar
        </button>
        <button
          onClick={(event) => {
            navigate(`/passaros/${id}/carteirinha-impressa`);
          }}
          className="rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
        >
          Imprimir carteirinha
        </button>
      </div>
      <div className="px-4 sm:px-0 pt-3">
        <h3 className="text-lg font-semibold leading-7 text-gray-900">
          Dados do pássaro.
        </h3>
      </div>
      <div ref={contentDocument} className={''}>
        <style type="text/css" media="print">
          {
            '\
            @media print {\
              @page { size: landscape; }\
            }\
      '
          }
        </style>
        <div className={`pt-2`}>
          {/* <img src={logo} className="" alt="logo" width={'55%'} /> */}
          <div className="border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  Nome do pássaro: <br />
                  <span className="font-bold text-5xl">{dto?.name ?? ''}</span>
                </dt>
              </div>

              <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      Anilha IBAMA:{' '}
                      <span className="font-normal">
                        {dto?.ringNumber ?? ''}
                      </span>
                    </div>
                    <div className="col-span-3">
                      Tipo:{' '}
                      <span className="font-normal">{dto?.type ?? ''}</span>
                      {/* <span className="font-normal">Bicudo (Sporophilia maximiliani)</span> */}
                    </div>

                    <div className="col-span-3">
                      Nascimento:{' '}
                      <span className="font-normal">
                        {dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''}
                      </span>
                    </div>
                    <div className="col-span-3">
                      Sexo:{' '}
                      <span className="font-normal">
                        {(dto?.gender === 'F' && 'Fêmea') ||
                          (dto?.gender === 'M' && 'Macho') ||
                          ''}
                      </span>
                    </div>
                  </div>
                </dt>
              </div>

              {/* Desenvolvimento, Status, Pai, Mãe */}
              <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3">
                      Tempo de desenvolvimento: <br />
                      <span className="font-normal">{dto?.age ?? ''}</span>
                    </div>
                    <div className="col-span-3">
                      Status: <br />
                      <span className="font-normal">
                        {dto?.status?.name ?? ''}
                      </span>
                    </div>

                    <div className="col-span-3">
                      Pai:{' '}
                      <span className="font-normal">
                        {dto?.dad?.name ?? ''}
                      </span>
                    </div>
                    <div className="col-span-3">
                      Mãe:{' '}
                      <span className="font-normal">
                        {dto?.mom?.name ?? ''}
                      </span>
                    </div>
                  </div>
                </dt>
              </div>

              {/* <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  <div className="grid grid-cols-6">
                    <div className="col-span-4">
                      Nascimento:{' '}
                      <span className="font-normal">
                        {dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''}
                      </span>
                    </div>
                    <div className="col-span-2">
                      Sexo:{' '}
                      <span className="font-normal">
                        {(dto?.gender === 'F' && 'Fêmea') ||
                          (dto?.gender === 'M' && 'Macho') ||
                          ''}
                      </span>
                    </div>
                  </div>
                </dt>
              </div> */}

              {/* <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  <div className="grid grid-cols-6">
                    <div className="col-span-4">
                      Tempo de desenvolvimento: <br />
                      <span className="font-normal">{dto?.age ?? ''}</span>
                    </div>
                    <div className="col-span-2">
                      Status: <br />
                      <span className="font-normal">
                        {dto?.status?.name ?? ''}
                      </span>
                    </div>
                  </div>
                </dt>
              </div> */}

              {/* <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  <div className="grid grid-cols-6">
                    <div className="col-span-4">
                      Pai:{' '}
                      <span className="font-normal">
                        {dto?.dad?.name ?? ''}
                      </span>
                    </div>
                    <div className="col-span-2">
                      Mãe:{' '}
                      <span className="font-normal">
                        {dto?.mom?.name ?? ''}
                      </span>
                    </div>
                  </div>
                </dt>
              </div> */}

              {/* <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  Pai:{' '}
                  <span className="font-normal">{dto?.dad?.name ?? ''}</span>
                </dt>
              </div>
              <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  Mãe:{' '}
                  <span className="font-normal">{dto?.mom?.name ?? ''}</span>
                </dt>
              </div> */}
              <div className="px-4 pt-6 pb-2 sm:px-0">
                <dt className="text-base font-medium leading-6 text-gray-900">
                  Observações:{' '}
                  <span className="font-normal break-words">
                    {dto?.obs ?? ''}
                  </span>
                </dt>
              </div>
            </dl>
          </div>
        </div>
        <div>
          {id && (
            // <FamilyTree id={id} translate={{ x: 120, y: 0.4 }} />
            <div className="mt-20 grid grid-cols-6 items-start justify-center">
              <div className='col-span-2'>
                <h2 className="font-bold text-3xl">Árvore genealógica</h2>
                <img src={logo} className="" alt="logo" width={'50%'} />
              </div>
              <div className='col-span-4' /* className="ml-40" style={{ marginTop: -180 }} */>
                <Genealogy id={id} />
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
