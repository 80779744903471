import { toast } from 'react-toastify';
import { TreeBird } from '../components/pages/birds/Genealogy';
import * as ApiService from '../services/api';

export const getFamilyTree = async (
  id: string | number
): Promise<TreeBird | undefined> => {
  try {
    if (!id) {
      toast.error(
        'Ocorreu um erro ao tentar buscar a árvore genealógica do pássaro.'
      );
      console.error(
        'O ID é obrigatório para buscar o pássaro para mostrar a árvore genealógica'
      );

      return;
    }

    const result = await ApiService.getOne<TreeBird>({
      endpoint: `/family-tree`,
      id
    });

    // console.log(result);

    if (result.isError === false && result?.data) {
      const { data } = result;
      if (data) {
        return data;
      }
      return;
    }

    toast.error(
      result?.data?.error ||
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
    );
  } catch (error) {
    toast.error(
      'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
    );
  }
};
