import { IconType } from 'react-icons/lib/cjs';
import { FiHome } from 'react-icons/fi';
import { GiBirdCage } from "react-icons/gi";
import { GrUserSettings } from "react-icons/gr";
import { LuBird } from "react-icons/lu";
import { FaUsers, FaKiwiBird } from 'react-icons/fa6';
import { CgNotes } from 'react-icons/cg';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { FaRegChartBar } from 'react-icons/fa';
import { CiUser } from 'react-icons/ci';
import { FaUserTie } from 'react-icons/fa6';
export interface MenuInterface {
  title: string;
  path?: string;
  onlyAdmin?: boolean;
  icon?: IconType;
  subMenus?: MenuInterface[];
}

enum Path {
  admin = '/admin',
  passaros = '/passaros',
  usuarios = '/usuarios',
  criadouros = '/criadouros'
}

//import HomeSvg from 'assets/icone/home.svg';

const Menu: MenuInterface[] = [
  /* {
    title: 'painel',
    path: Path['admin'],
    icon: FiHome
  }, */
  /* {
    title: 'Usuários',
    icon: GrUserSettings,
    subMenus: [
      {
        title: 'Ver usuários',
        path: `${Path['admin']}${Path['usuarios']}`
      },
      {
        title: 'Cadastrar novo usuário',
        path: `${Path['admin']}${Path['usuarios']}/cadastro`
      }
    ]
  }, */
  {
    title: 'Pássaros',
    icon: LuBird,
    subMenus: [
      {
        title: 'Ver pássaros',
        path: `${Path['admin']}${Path['passaros']}`
      },
      {
        title: 'Cadastrar novo pássaro',
        path: `${Path['admin']}${Path['passaros']}/cadastro`
      }
    ]
  },
  {
    // TODO: finalizar este menu e todo o componentende User.
      // TODO: finalizar cadastro e listagem de usuários (front e back)
    // TODO: adicionar a logo na sidebar e no documento.
    // TODO: tentar encaixar a logo na impressão do documento.
    // TODO: testar a parte da impressão pra ver se tá de boa. 
    // TODO: testar árvores maiores e ver até onde aguenta. 




    title: 'Usuários',
    onlyAdmin: true,
    icon: CiUser,
    subMenus: [
      {
        title: 'Ver usuários',
        path: `${Path['admin']}${Path['usuarios']}`
      },
      {
        title: 'Cadastrar novo usuário',
        path: `${Path['admin']}${Path['usuarios']}/cadastro`
      }
    ]
  },
  /* {
    title: 'Criadouros',
    icon: GiBirdCage,
    subMenus: [
      {
        title: 'Ver criadouros',
        path: `${Path['admin']}${Path['criadouros']}`
      },
      {
        title: 'Cadastrar novo criadouro',
        path: `${Path['admin']}${Path['criadouros']}/cadastro`
      }
    ]
  } */
];

export default Menu;
