/* eslint-disable @typescript-eslint/no-explicit-any */
import {ForgotPassData, LoginData, Usuario} from '../types';
import api, { RequestError, RequestResponse } from './api';

const authApi =  {
  async login(loginData: LoginData): Promise<RequestError | RequestResponse<{ status: true; message: string; data: any, token: string; usuario: Usuario; menu: any }>> {
    try {
      const response = await api.post('/auth/app-login', loginData);
      
      
      return { data: response?.data ?? {}, isError: false };
    } catch (err: any) {
      console.log('err');
      console.log(err);
      return { 
        
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async forgotPass(forgotPassData: ForgotPassData): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.post('usuarios/resetar-senha', forgotPassData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async logout(id: string | number): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.delete(`auth/app-logout/${id}`);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async changePassword(id: number | undefined, dataForm: any): Promise<RequestError | RequestResponse<{ status: true; message: string }>> {
    try {
      const { data } = await api.put(`/user/${id}/password`, dataForm);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

};

export default authApi;